import { CHAIN_ID } from "./settings";

const state = process.env.GATSBY_ENV_STATE;
const INFURA_TOKEN = process.env.INFURA_TOKEN;

const NETWORK_LIST = {
  1: "mainnet",
  3: "ropsten",
  4: "rinkeby",
  5: "goerli",
  42: "kovan",
};

export const SAFESOUL_API_URL = state === "true" ? "https://safesoul.test-dev.site" : "https://api.safesoul.club";

export const DISCORD_URL = 'https://discord.gg/SoulsClub';

export const TWITTER_URL = 'https://twitter.com/SafeSouleth';

export const getNetworkName = (chainId) => NETWORK_LIST[chainId] || "mainnet";

export const CRYPTO_PROVIDER = `https://${getNetworkName(CHAIN_ID)}.infura.io/v3/${INFURA_TOKEN}`