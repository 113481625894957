/*
    CHAIN_ID = 5        Polygon Goerli Testnet
    CHAIN_ID = 137      Polygon Mainnet
*/
export const CONTENTFUL_SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
export const CONTENTFUL_PREVIEW_TOKEN = process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN;
export const IS_PRODUCTION = process.env.GATSBY_ENV_STATE !== 'true';

export const CHAIN_ID = 5;

export const AVAILABLE_TOKENS_TO_MINT = 20;
export const AVAILABLE_TOTAL_TOKENS_TO_MINT = 100000;
export const INTERVAL_TOTAL_MINTED_UPDATE = 5000;

export const SAFESOUL_CONTRACT_ADDRESS = '0x40459F3dc6eB0F7478ba68A33A86ddd7c1B40A9A';
export const SAFESOUL_PATROL_CONTRACT_ADDRESS = '0x54b33F8722E8DC44A989c6870bf34B2283391B3C';