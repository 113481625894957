import React, { useEffect, useState, useCallback, useMemo } from 'react';
import client from '../../helpers/apiClient';

import './styles.css';

const Achievement = (prop) => {
  const [achievement, setAchievement] = useState(null);
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    if (!window?.matchMedia) return;

    const theme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

    setTheme(theme);
  }, []);

  const getAchievement = useCallback(async () => {
    if (!prop.id) return;

    try {
      const { data } = await client(`achievement/${prop.id}`);

      setAchievement(data);
    } catch (e) {
      console.log(e);
    };
  }, [prop.id]);

  useEffect(() => {
    getAchievement();
  }, [getAchievement]);

  const source = useMemo(() => {
    if (!achievement) return null;

    return achievement[theme]?.video_full || achievement[theme]?.image;
  }, [theme, achievement]);

  const isVideo = useMemo(() =>
    source && ['.mp4', '.webm', '.ogg', '.mov', '.avi', '.wmv']
      .some(i => source.includes(i)),
    [source]
  );

  if (!source) {
    return <p></p>;
  }

  return (
    <div className='achivements__overlay'>
      <div className='achivements__inner'>
        <div className='achivements__video-wrapper'>
          {
            isVideo ?
              <video className='achivements__video' autoPlay loop muted src={source} poster={achievement[theme].image} />
              :
              <img className='achivements__video' src={source} alt={achievement.name} />
          }
        </div>

        <div className='ss-achivements__bottom-block'>
          <div className='ss-achivements__text-wrapper'>
            <div className='ss-achivements__title'>{achievement.name}</div>
            <div className='ss-achivements__description'>{achievement.description}</div>
          </div>
          <div className='ss-achivements__multiple-block'>
            <strong>x{achievement.multiplier}</strong>
            <span>Multiplier</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Achievement;